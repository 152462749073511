<template>
  <section>
    <e-filters
      :searching="fetching"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <div>
        <b-row>
          <b-col md="2">
            <FormulateInput
              id="order-id"
              v-model="filters.saleId"
              name="orderId"
              :label="$t('Id da venda')"
              type="text"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="order-quotation_id"
              v-model="filters.quotationId"
              name="orderQuotationId"
              :label="$t('Id da cotação')"
              type="text"
            />
          </b-col>
          <b-col md="4">
            <e-store-combo
              id="order-store_id"
              v-model="filters.storeId"
              :required="false"
            />
          </b-col>

          <b-col md="4">
            <e-search-sku
              id="product-name"
              v-model="filters.skuId"
              :required="false"
            />
          </b-col>

          <b-col md="2">
            <FormulateInput
              id="order-price_table"
              v-model="filters.priceTableId"
              type="vue-select"
              :label="$t('Tabela de preço')"
              :placeholder="$t('Todos')"
              :options="getComboPriceTables"
            />
          </b-col>
          <b-col md="7">
            <FormulateInput
              id="order-status"
              v-model="filters.status"
              name="delivery"
              multiple
              :label="$t('Status Pedido')"
              :placeholder="$t('Todos')"
              type="vue-select"
              :options="saleStatusOptions()"
            />
          </b-col>
          <b-col md="3">
            <FormulateInput
              id="order-delivery-status"
              v-model="filters.deliveryStatus"
              name="delivery"
              multiple
              :label="$t('Status Entrega')"
              :placeholder="$t('Todos')"
              type="vue-select"
              :options="deliveryStatusOptions()"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="order-status"
              v-model="filters.origin"
              name="delivery"
              :label="$t('Origem')"
              :placeholder="$t('Todos')"
              type="vue-select"
              :options="saleOriginToOrderOptions()"
            />
          </b-col>
          <b-col md="4">
            <e-person-search
              id="order-customer_id"
              v-model="filters.customerId"
              :label="$t('Cliente')"
              is-customer
              :only-active="false"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="order-period_type"
              v-model="filters.periodType"
              type="vue-select"
              :label="$t('Tipo do período')"
              :options="orderDates()"
              :clearable="false"
            />
          </b-col>
          <b-col md="4">
            <FormulateInput
              id="period"
              v-model="filters.rangeDate"
              type="date-range-picker"
              :label="$t('Período')"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="order-echope_classification"
              v-model="filters.echopeClassification"
              type="vue-select"
              :label="$t('Classificação echope')"
              :placeholder="$t('Todos')"
              :options="echopeClassificationOptions()"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="order-is_consigned"
              v-model="filters.isConsigned"
              type="vue-select"
              :label="$t('Consignado')"
              :placeholder="$t('Todos')"
              :options="yesNoTypes()"
            />
          </b-col>
          <b-col md="2">
            <FormulateInput
              id="order-delivery"
              v-model="filters.isDelivery"
              name="delivery"
              :label="$t('Entrega')"
              :placeholder="$t('Todos')"
              type="vue-select"
              :options="yesNoTypes()"
            />
          </b-col>
        </b-row>
      </div>
    </e-filters>

    <b-card-actions
      action-refresh
      :show-loading="fetching"
      @refresh="getData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>

      <b-table
        id="order-table"
        show-empty
        responsive
        striped
        class="bordered"
        :empty-text="getEmptyTableMessage($tc('SALE_ORDER.NAME'))"
        :fields="fields"
        :items="orders"
        :tbody-tr-class="rowClass"
        no-local-sorting
        :sort-by.sync="sorting.sortBy"
        :sort-desc.sync="sorting.sortDesc"
        @sort-changed="getData"
      >
        <template #cell(delivery)="row">
          <e-status-badge
            :status="row.item.delivery"
            type="yesNo"
          />
        </template>

        <template #cell(consigned)="row">
          <e-status-badge
            :status="row.item.consigned"
            type="yesNo"
          />
        </template>

        <template #cell(status)="row">
          <e-status-badge
            :status="row.item.status"
            :options="saleStatusOptions()"
          />
          <span v-if="row.item.status === 'Canceled'">
            {{ row.item.cancelationDate | datetime }}
          </span>
        </template>

        <template #cell(deliveryStatus)="row">
          <e-status-badge
            v-if="row.item.delivery"
            :status="row.item.deliveryStatus"
            :options="deliveryStatusOptions()"
          />
        </template>

        <template #custom-foot>
          <tr>
            <th
              colspan="11"
              class="text-right"
            >
              Total
            </th>
            <th class="text-center">
              {{ netValue | currency }}
            </th>
          </tr>
        </template>

        <template #cell(action)="row">
          <e-grid-actions
            :is-active="row.item.active"
            :show-update="row.item.status !== saleStatusEnum.COMPLETED &&
              row.item.status !== saleStatusEnum.CANCELED
            "
            :show-delete="false"
            :show-activate="false"
            :show-deactivate="false"
            :buttons="buttons(row.item)"
            @cancel-order="cancelOrder(row)"
            @order-read-only="showOrderReadOnly(row)"
            @update="showOrderUpdate(row)"
            @activate="activateDeactivateOrder(row)"
            @deactivate="activateDeactivateOrder(row)"
            @return-consigned="showReturnConsignedModal(row)"
            @change-delivery-status="onChangeDeliveryStatus(row)"
            @print-order="onPrint(row)"
            @check-payment="onCheckPayment(row)"
          />
        </template>
      </b-table>
      <b-row class="pt-1">
        <b-col cols="3">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <e-color-subtitled
            :colors="[
              { backgroundColor: 'table-success', title: $t('Pago') },
              { backgroundColor: 'table-danger', title: $t('Cancelado') },
              { backgroundColor: 'table-warning', title: $t('Consignado pendente de pagamento') },
            ]"
          />
        </b-col>
        <b-col cols="3">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="order-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <e-modal-order-cancelation
      ref="modalCancelation"
      @after-confirm="getData"
    />

    <modal-return-items
      ref="modalReturnConsignedItems"
      show-sale-info
      show-products
      is-erp
      :busy="returnItemsBusy"
      @confirm="onConfimPartialReturnConsign"
    />

    <fab
      :main-tooltip="$t('Adicionar Pedido')"
      @click="onCreateOrder"
    />
  </section>
</template>

<script>
import { BRow, BCol, BPagination, BTable } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  EPageSizeSelector,
  EPaginationSummary,
  EFilters,
  EGridActions,
  EStatusBadge,
} from '@/views/components'
import fab from '@/views/components/FAB.vue'
import {
  saleDomain,
  invoiceDomains,
  statusTypes,
  loadingOverlay,
  deliveryDomain,
  orderDates,
} from '@/mixins'
import { mapActions, mapGetters, mapState } from 'vuex'
import EStoreCombo from '@/views/components/inputs/EStoreCombo.vue'
import EPersonSearch from '@/views/components/inputs/EPersonSearch.vue'
import EColorSubtitled from '@/views/components/EColorSubtitled.vue'
import ESearchSku from '@/views/components/inputs/ESearchSku.vue'
import isElectron from 'is-electron'
import EModalOrderCancelation from './EModalOrderCancelation.vue'
import ModalReturnItems from '../../pdv/pay-box/components/modals/ModalReturnItems.vue'

export default {
  components: {
    BRow,
    BCol,
    BCardActions,
    BPagination,
    BTable,
    EPaginationSummary,
    EPageSizeSelector,
    fab,
    EFilters,
    EGridActions,
    EStatusBadge,
    EStoreCombo,
    EPersonSearch,
    EModalOrderCancelation,
    ModalReturnItems,
    EColorSubtitled,
    ESearchSku,
  },

  mixins: [statusTypes, saleDomain, invoiceDomains, loadingOverlay, deliveryDomain, orderDates],

  data() {
    return {
      fetching: false,
      returnItemsBusy: false,
    }
  },

  computed: {
    ...mapGetters('common/priceTables', ['getComboPriceTables']),
    ...mapState('pages/sale/order', ['orders', 'paging', 'sorting', 'filters', 'summaryFields']),
    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Data Venda'),
          key: 'createdDate',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px', minWidth: '100px' },
          sortable: true,
          formatter: val => this.$options.filters.datetime(val),
        },
        {
          label: this.$t('Id'),
          key: 'id',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '80px' },
          sortable: true,
        },
        {
          label: this.$t('Origem'),
          key: 'origin',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          formatter: val => this.saleOriginLabel[val] || '-',
        },
        {
          label: this.$t('Loja'),
          key: 'store',
          thClass: 'text-center',
          tdClass: 'text-left',
          thStyle: { width: '300px' },
          formatter: val => this.$options.filters.storeName(val),
        },
        {
          label: this.$t('Cliente'),
          key: 'customer.name',
          thClass: 'text-center',
          tdClass: 'text-left',
          // formatter: val => val.name,
          sortable: true,
        },
        {
          label: this.$t('Fiscal'),
          key: 'invoice.invoiceNumber',
          thClass: 'text-center',
          tdClass: 'text-left',
          formatter: (val, index, item) =>
            item.invoice ? `${this.invoiceModelLabelShort[item.invoice.model]}: ${val}` : '-',
          thStyle: { width: '120px' },
          sortable: true,
        },
        {
          label: this.$t('Data Entrega'),
          key: 'deliveryDate',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
          formatter: val => this.$options.filters.date(val, '-'),
        },
        {
          label: this.$t('Consignado'),
          key: 'consigned',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Status Pedido'),
          key: 'status',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          formatter: val => this.saleStatusLabel[val] || '-',
        },
        {
          label: this.$t('Status Entrega'),
          key: 'deliveryStatus',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          formatter: val => this.deliveryStatusLabel[val] || '-',
        },
        {
          label: this.$t('Valor'),
          key: 'netValue',
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: { width: '130px' },
          sortable: true,
          formatter: val => this.$options.filters.currency(val),
        },
      ]
    },

    netValue() {
      return this.summaryFields?.netValue?.value
    },

    buttons() {
      return item => {
        const showBtns = [
          {
            icon: 'search',
            variant: 'outline-primary',
            title: this.$t('Visualizar Pedido'),
            event: 'order-read-only',
          },
        ]

        if (item.origin !== this.saleOriginEnum.INVOICE) {
          if (item.status !== this.saleStatusEnum.CANCELED) {
            showBtns.push({
              icon: 'x',
              variant: 'danger',
              title: this.$t('Cancelar Pedido'),
              event: 'cancel-order',
            })
          }

          if (isElectron()) {
            showBtns.push({
              icon: 'printer',
              event: 'print-order',
              color: 'green',
              title: this.$t('Imprimir'),
            })
          }
        }

        if (
          item.consigned &&
          item.status !== this.saleStatusEnum.COMPLETED &&
          item.status !== this.saleStatusEnum.CANCELED
        ) {
          showBtns.push({
            icon: 'arrow-return-left',
            variant: 'outline-primary',
            title: this.$t('Retorno do consignado'),
            event: 'return-consigned',
          })
        }

        if (
          item.deliveryStatus &&
          item.deliveryStatus !== this.deliveryStatusEnum.COMPLETED &&
          item.deliveryStatus !== this.deliveryStatusEnum.CANCELED
        ) {
          showBtns.push({
            icon: 'truck',
            variant: 'warning',
            title: this.$t('Próximo status de entrega'),
            event: 'change-delivery-status',
          })
        }

        if (item.status === this.saleStatusEnum.WAITING_PAYMENT) {
          showBtns.push({
            icon: 'cash',
            variant: 'success',
            title: this.$t('Consultar status do pagamento'),
            event: 'check-payment',
          })
        }

        return showBtns
      }
    },
  },

  mounted() {
    this.loadPdvPaymentMethods()
    this.fetchPriceTables()
    this.getData()
  },

  methods: {
    ...mapActions('common/priceTables', ['fetchPriceTables']),
    ...mapActions('pages/sale/order', [
      'fetchOrders',
      'resetFilters',
      'setCurrentPage',
      'setPageSize',
      'printOrder',
      'fetchSaleById',
      'checkPaymentStatus',
    ]),
    ...mapActions('pages/sale/order/orderMaintain', {
      stCleanOrderMaintainState: 'cleanState',
    }),
    ...mapActions('pages/pdv', {
      loadPdvPaymentMethods: 'loadPaymentMethods',
    }),

    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchOrders()
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    async onPrint(row) {
      try {
        const { id } = row.item
        this.busy = true
        this.showLoadingOverlay(this.$t('Buscando dados para impressão'))

        const orderPrint = await this.fetchSaleById(id)

        const validToPrint = this.mxValidatePrintForOnlineSale(orderPrint)
        if (!validToPrint.valid) {
          this.showWarning({ message: validToPrint.message })
          return
        }

        if (orderPrint.deliveryStatus === this.deliveryStatusEnum.PENDING) {
          this.showLoadingOverlay(this.$t('Atualizando status'))
          await this.onChangeDeliveryStatus({ item: orderPrint })
        }

        this.showLoadingOverlay(this.$t('Imprimindo cupom'))
        await this.printOrder({ orderPrint, template: 'SALE_ORDER' })

        this.showSuccess({ message: '' })
      } catch (err) {
        /* eslint-disable no-console */
        console.error('print:', err)
        this.busy = false
      } finally {
        this.hideLoadingOverlay()
      }
    },

    async onCheckPayment(row) {
      try {
        const { id } = row.item
        this.busy = true
        this.showLoadingOverlay(this.$t('Consultando status do pagamento'))

        const paymentStatus = await this.checkPaymentStatus(id)

        switch (paymentStatus) {
          case 'Approved':
            this.confirm({
              icon: 'success',
              title: this.$t('Status de pagamento'),
              text: this.$t('O pagamento foi confirmado!'),
              showCancelButton: false,
              confirmButtonText: 'Ok',
            })
            await this.fetchOrders()
            break
          default:
            this.confirm({
              icon: 'error',
              title: this.$t('Status de pagamento'),
              text: this.$t('Não foi identificado nenhum pagamento!'),
              showCancelButton: false,
              confirmButtonText: 'Ok',
            })
            break
        }
      } catch (error) {
        this.busy = false
        this.showError({ error })
      } finally {
        this.hideLoadingOverlay()
      }
    },

    filterData() {
      this.setCurrentPage(1)
      this.getData()
    },
    resetFiltersLocal() {
      this.resetFilters()
      this.setCurrentPage(1)
      this.getData()
    },
    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },
    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },

    onCreateOrder() {
      this.stCleanOrderMaintainState()
      this.$router.push({ name: 'order-add' })
    },

    async showOrderReadOnly(row) {
      const { id } = row.item
      this.$router.push({ name: 'order-read-only', params: { id } })
    },

    async showOrderUpdate(row) {
      const { id } = row.item
      this.$router.push({ name: 'order-maintain', params: { id } })
    },

    async showReturnConsignedModal(row) {
      const { id } = row.item
      this.$refs.modalReturnConsignedItems.showModalReturn(id)
    },

    async onChangeDeliveryStatus(row) {
      try {
        this.fetching = true
        await this.mxChangeDeliveryStatus(row.item)
        await this.getData()
      } catch (error) {
        this.showError({ error })
      } finally {
        this.fetching = false
      }
    },

    async onConfimPartialReturnConsign(data) {
      try {
        this.returnItemsBusy = true
        const itemsReturned = data.returnedItems
          .filter(i => i.quantity > 0)
          .map(i => ({
            id: i.itemReturnedId,
            skuId: i.skuProduct.skuId,
            saleItemId: i.saleItemId,
            quantity: i.quantity,
            unitValue: i.priceRuleSelected.priceInfo.unitValue,
            discountType: i.priceRuleSelected.priceInfo.discountType,
            unitDiscount: i.priceRuleSelected.priceInfo.unitDiscount,
          }))

        if (itemsReturned.length > 0) {
          const payload = {
            id: data.saleId,
            itemsReturned,
          }

          await this.$http.put('/api/sales/orders/return-consigned', payload)
        }

        this.$refs.modalReturnConsignedItems.hideModal()
      } catch (error) {
        this.showError({ error })
      } finally {
        this.returnItemsBusy = false
      }
    },

    async cancelOrder(row) {
      if (
        row.item?.invoice?.model === 'NFE' &&
        row.item?.invoice?.invoiceCategory === 'SaleFromPdv'
      ) {
        this.showInvalidDataMessage({
          message: this.$t(
            'Existe uma NF-e atrelada a esta venda! Favor realizar o cancelamento pelo PDV.'
          ),
        })
        return
      }

      const confirmed = await this.confirm({
        title: this.$t('Cancelar Pedido?'),
      })

      if (!confirmed) return

      this.$refs.modalCancelation.show(row.item.id, row.item)
    },

    rowClass(item, type) {
      if (!item || type !== 'row') return ''
      if (item.status === this.saleStatusEnum.CANCELED) return 'table-danger'

      if (item.pendingPaymentValue == null || item.pendingPaymentValue === 0) return 'table-success'

      if (item.consigned && item.pendingPaymentValue > 0) return 'table-warning'

      return ''
    },
  },
}
</script>
